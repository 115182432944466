import React from 'react';
import { 
  Briefcase, 
  MessageSquare, 
  BarChart2, 
  Code2, 
  Search,
  Mail,
  ArrowRight
} from 'lucide-react';

const Career = () => {
  const careers = [
    {
      title: "Sales",
      icon: <BarChart2 className="w-6 h-6" />,
      description: "Drive growth and build relationships with educational institutions."
    },
    {
      title: "Marketing",
      icon: <MessageSquare className="w-6 h-6" />,
      description: "Create compelling campaigns to reach students worldwide."
    },
    {
      title: "Product Development",
      icon: <Briefcase className="w-6 h-6" />,
      description: "Shape the future of online education platforms."
    },
    {
      title: "Engineering",
      icon: <Code2 className="w-6 h-6" />,
      description: "Build scalable solutions that impact millions of learners."
    },
    {
      title: "Internship",
      icon: <Search className="w-6 h-6" />,
      description: "Gain real-world experience in the tech industry."
    },
    {
      title: "Other roles",
      icon: <Search className="w-6 h-6" />,
      description: "Don't see your perfect role? We're always looking for talent."
    }
  ];

  return (
    <section className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Join Our Mission
          </h2>
          <p className="text-xl text-gray-600 leading-relaxed">
            We are looking for passionate individuals who are eager to make a difference 
            in democratizing education worldwide.
          </p>
        </div>

        {/* Careers Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {careers.map((career) => (
            <div 
              key={career.title}
              className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-2 bg-blue-50 rounded-lg">
                  {career.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  {career.title}
                </h3>
              </div>
              <p className="text-gray-600 mb-4">
                {career.description}
              </p>
              {/* <button className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center group">
                Learn more 
                <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
              </button> */}
            </div>
          ))}
        </div>

        {/* Contact Section */}
        <div className="max-w-2xl mx-auto text-center bg-white p-8 rounded-2xl shadow-md">
          <Mail className="w-12 h-12 text-blue-600 mx-auto mb-4" />
          <h3 className="text-2xl font-semibold mb-4">
            Ready to Make an Impact?
          </h3>
          <p className="text-gray-600 mb-6">
            Send your resume and cover letter to start your journey with us.
          </p>
          <a 
            href="mailto:opencourse.team@gmail.com"
            className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            <Mail className="w-5 h-5 mr-2" />
            opencourse.team@gmail.com
          </a>
        </div>
      </div>
    </section>
  );
};

export default Career;