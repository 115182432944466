import React from 'react';

const ContactUs = () => {
  return (
    <section id="contact-us" style={{ padding: '20px' }}>
      <h2>Contact Us</h2>
      <p>
        Have any questions? Reach out to us at{' '}
        <a href="mailto:opencourse.team@gmail.com">opencourse.team@gmail.com</a>.
      </p>
    </section>
  );
};

export default ContactUs;
