import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const SignInPage = () => {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h4">Sign In</Typography>
        {/* You can add a sign-in form here */}
      </Box>
    </Container>
  );
};

export default SignInPage;

