// src/services/auth.js
import axios from 'axios';

const API_URL = 'http://localhost:8000';

export const loginWithGoogle = async (googleToken) => {
  try {
    const response = await axios.post(`${API_URL}/auth/google/auth`, {
      token: googleToken,
    });
    localStorage.setItem('token', response.data.access_token);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginWithEmail = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/token`, {
      username: email,
      password: password,
    });
    localStorage.setItem('token', response.data.access_token);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, {
      email,
      password,
      auth_provider: 'email',
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};