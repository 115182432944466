import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import CourseListingPage from './CourseList';
import CourseDescription from './CourseDescription';
import AppBar from './AppBar';
import AboutUs from './AboutUs';
import Career from './Career';
import ContactUs from './ContactUs';
import Footer from './Footer';

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import CourseVideoPage from './CourseVideo/CourseVideoPage';

const firebaseConfig = {
  apiKey: "AIzaSyA29yzxK5KKOP3PqIZYwsARdRpSe_Ji-O8",
  authDomain: "open-course-2d2ae.firebaseapp.com",
  projectId: "open-course-2d2ae",
  storageBucket: "open-course-2d2ae.firebasestorage.app",
  messagingSenderId: "1031236649834",
  appId: "1:1031236649834:web:7cd86c33a093e7f9cd5b13",
  measurementId: "G-D17E5VSQ40"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <Router>
      <AppBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/courses" element={<CourseListingPage />} />
        <Route path="/course-detail/:title" element={<CourseDescription />} />
        {/* <Route path="/" element={<CoursePage /> } /> */}
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/leadership" element={<Leadership />} /> */}
        <Route path="/career" element={<Career />} />
        {/* <Route path="/catalog" element={<CourseListingPage />} /> */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/video" element={<CourseVideoPage />} />
        
      </Routes>
      
      <Footer />

    </Router>
  );
}

export default App;

// import React from 'react';
// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import axios from 'axios';

// const App = () => {
//   const handleGoogleLoginSuccess = async (response) => {
//     console.log(response)
//     console.log('response.credential', response.credential)
//     const idToken = response.credential;
//     try {
//       const result = await axios.post("http://localhost:8000/auth/google/verify", { token: idToken });
//       console.log("User Data:", result.data);      
//     } catch (error) {
//       console.error("Google login failed:", error);
//     }
//   };

//   const handleGoogleLoginFailure = (error) => {
//     console.error("Login Failed:", error);
//   };

//   return (
//     <GoogleOAuthProvider clientId="300023393777-ecqsog2qgcb36h8m43rjgj58htuas0lk.apps.googleusercontent.com">
//       <div className="App">
//         <h2>Login with Google</h2>
//         <GoogleLogin
//           onSuccess={handleGoogleLoginSuccess}
//           onFailure={handleGoogleLoginFailure}
//           useOneTap
//         />
//       </div>
//     </GoogleOAuthProvider>
//   );
// };

// export default App;
