import React from 'react';
import { Book, Target, Heart } from 'lucide-react';

const AboutUs = () => {
  const cards = [
    {
      title: "Mission",
      description: "To make education accessible to all, regardless of geographical or financial constraints.",
      icon: <Book className="w-8 h-8 text-blue-600" />,
    },
    {
      title: "Vision",
      description: "A world where everyone has the opportunity to learn and grow without barriers.",
      icon: <Target className="w-8 h-8 text-green-600" />,
    },
    {
      title: "Values",
      description: "Equality, Innovation, and Collaboration.",
      icon: <Heart className="w-8 h-8 text-red-600" />,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6 sm:text-5xl">
            About Us
          </h1>
          <p className="text-xl text-gray-600 leading-relaxed">
            Our vision is to democratize education by providing free, high-quality courses 
            and certifications from top universities like MIT, Stanford, and Harvard, 
            accessible to everyone.
          </p>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {cards.map((card, index) => (
            <div 
              key={card.title}
              className="group bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex flex-col items-center text-center space-y-4">
                <div className="p-3 bg-gray-50 rounded-full group-hover:scale-110 transition-transform duration-300">
                  {card.icon}
                </div>
                <h3 className="text-2xl font-semibold text-gray-900">
                  {card.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;