import React from 'react';
import { ArrowRight, Sparkles, Users } from 'lucide-react';

const CallToAction = () => {
  const stats = [
    { icon: <Users className="w-6 h-6" />, label: 'Community', description: 'Active Learners' },
    { icon: <Sparkles className="w-6 h-6" />, label: '100+ Courses', description: 'Expert-Led Content' }
  ];

  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-blue-600 to-blue-400">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute transform rotate-45 -right-1/4 -top-1/4 w-96 h-96 bg-white rounded-full" />
        <div className="absolute -left-1/4 -bottom-1/4 w-96 h-96 bg-white rounded-full" />
      </div>

      <div className="relative px-4 py-16 mx-auto max-w-4xl">
        <div className="text-center space-y-8">
          {/* Main Content */}
          <div className="space-y-4">
            <h2 className="text-4xl font-bold text-white md:text-5xl">
              Start Your Learning Journey Today
            </h2>
            <p className="text-xl text-blue-50 max-w-2xl mx-auto">
              Join learners worldwide and access top-quality courses from leading universities and companies.
            </p>
          </div>

          {/* Statistics */}
          <div className="grid grid-cols-2 gap-8 max-w-lg mx-auto">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="flex justify-center mb-2">{stat.icon}</div>
                <div className="text-2xl font-bold text-white">{stat.label}</div>
                <div className="text-blue-100">{stat.description}</div>
              </div>
            ))}
          </div>

          {/* CTA Button */}
          <div className="pt-6">
            <button 
              className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-blue-600 bg-white rounded-full shadow-lg hover:bg-blue-50 transition-all duration-300 transform hover:scale-105"
              onClick={() => console.log('Sign up clicked')}
            >
              Get Started For Free
              <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
            </button>
          </div>

          {/* Trust Indicators */}
          {/* <p className="text-sm text-blue-100 pt-4">
            No credit card required • 7-day free trial • Cancel anytime
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;