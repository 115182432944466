import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import { Search, Filter, GraduationCap, School, X } from 'lucide-react';

export default function CourseListingPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryFilter = params.get('categories')?.split(',') || [];
    const searchFilter = params.get('search') || '';

    setSelectedCategories(categoryFilter);
    setSearchTerm(searchFilter);
  }, [location.search]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`https://social.opencoursehub.online/api/search?data_type=descriptionV2&page=1&page_size=6&rephrased_title=${encodeURIComponent(searchTerm)}&category=${encodeURIComponent(selectedCategories.join(','))}`, {
          headers: { accept: 'application/json' },
        });
        const data = await response.json();
        setCourses(data.items); // Adjust this based on the structure of your response

        // setCategories([...new Set(data.items.map(course => course.category))].sort());
        setCategories(
          ['Computer Science', 'Engineering', 'Physics', 'Mathematics', 'Science', 'Business & Management', 'Biology', 'Education', 'Chemistry', 'Arts & Humanities', 'Social Sciences', 'Economics', 'Media & Communication', 'Neuroscience', 'Music', 'Language', 'Materials Science', 'Artificial Intelligence', 'Languages', 'Environmental Science', 'Film Studies', 'Data Science', 'Architecture', 'Medicine & Healthcare', 'Environmental Studies', 'History', 'Cognitive Science'])
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [searchTerm, selectedCategories]);

  const toggleCategory = (category) => {
    const updatedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter(item => item !== category)
      : [...selectedCategories, category];

    setSelectedCategories(updatedCategories);
    updateURLParams(searchTerm, updatedCategories);
  };

  const updateURLParams = (search, categories) => {
    const params = new URLSearchParams();
    if (search) params.set('search', search);
    if (categories.length) params.set('categories', categories.join(','));
    navigate({ search: params.toString() });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    updateURLParams(value, selectedCategories);
  };

  const filteredCourses = useMemo(() => {
    return courses.filter(course => {
      const matchesSearch = course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            course.university.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategories.length === 0 || selectedCategories.includes(course.category);
      return matchesSearch && matchesCategory;
    });
  }, [courses, searchTerm, selectedCategories]);

  return (
    <div className="min-h-screen bg-gray-50 relative">
      {/* Header */}
      <header className="bg-white shadow-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <GraduationCap className="h-8 w-8 text-blue-600" />
              <h1 className="text-2xl font-bold text-gray-900">Course Catalog</h1>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Search Box at the top */}
        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search courses..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full px-4 py-2 pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
            {searchTerm && (
              <button
                onClick={() => {
                  setSearchTerm('');
                  updateURLParams('', selectedCategories);
                }}
                className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
              >
                <X size={18} />
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar for Category Filter */}
          <aside className={`${isSidebarOpen ? 'block' : 'hidden'} lg:block w-full lg:w-64 space-y-6 transition-all`}>
            <div className="flex justify-between items-center lg:hidden">
              <h2 className="font-semibold text-gray-700 mb-4">Categories</h2>
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="text-gray-600 hover:text-gray-800"
                aria-label="Close Filter"
              >
                <X size={24} />
              </button>
            </div>

            {/* Category Filter with Checkboxes */}
            <div className="bg-white p-4 rounded-lg shadow">
              <h2 className="font-semibold text-gray-700 mb-4">Categories</h2>
              <div className="space-y-2">
                {categories.map((category) => (
                  <label key={category} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => toggleCategory(category)}
                      className="form-checkbox text-blue-600 rounded"
                    />
                    <span className="text-gray-600">{category}</span>
                  </label>
                ))}
              </div>
            </div>
          </aside>

          {/* Course Grid */}
          <div className="flex-1">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredCourses.map((course, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow overflow-hidden transform hover:scale-105"
                >
                  <div className="relative h-48 overflow-hidden">
                    <img
                      src={course.img}
                      alt={course.title}
                      className="w-full h-full object-cover transition-transform duration-300"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                      <div className="flex items-center space-x-2">
                        <School className="h-4 w-4 text-white" />
                        <span className="text-sm text-white">{course.university}</span>
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <h3 className="font-semibold text-gray-900 mb-2 line-clamp-2 h-12">
                      {course.title}
                    </h3>
                    <div className="flex flex-wrap gap-2 mb-3">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {course.category}
                      </span>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {course.subcategory}
                      </span>
                    </div>
                    <a href={`/course-detail/${course.id}`} className="w-full mt-2 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors">
                      View Course
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
