import React from 'react';
import { Container, Box, Button, Typography, Grid, IconButton, Divider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router-dom';
// import popularTopics from './popular_topics.json';


const Footer = () => {
  const navigate = useNavigate();

  const popularTopics = [
    {
      title: "Computer Science",
      url: "/courses?categories=Computer+Science"
    },
    {
      title: "Economics",
      url: "/courses?categories=Economics"
    },
    {
      title: "Biology",
      url: "/courses?categories=Biology"
    },
    {
      title: "Mathematics",
      url: "/courses?categories=Mathematics"
    },
    {
      title: "Physics",
      url: "/courses?categories=Physics"
    }
  ];

  return (
    <Box sx={{ backgroundColor: '#f5f5f5' }}>

      {/* Footer */}
      <Box sx={{ backgroundColor: '#333', color: '#fff', py: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Open Course
              </Typography>
              <Typography variant="body2">
                Empowering learners worldwide with accessible, high-quality education.
              </Typography>
              <Box sx={{ mt: 2 }}>
                {/* <IconButton color="inherit" aria-label="Facebook">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="inherit" aria-label="Twitter">
                  <TwitterIcon />
                </IconButton> */}
                <IconButton onClick={() => window.open('https://www.linkedin.com/company/open-course-hub/', '_blank')} color="inherit" aria-label="LinkedIn">
                  <LinkedInIcon />
                </IconButton>
                <IconButton onClick={() => window.open('https://github.com/Lucky-Rathore/OpenCourse', '_blank')} color="inherit" aria-label="Github">
                  <GitHubIcon />
                </IconButton>
                {/* <IconButton color="inherit" aria-label="Instagram">
                  <InstagramIcon />
                </IconButton> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Learn
              </Typography>
              <Typography variant="body2" component="div">
                <Box component="ul" sx={{ pl: 0, listStyle: 'none' }}>
                  {popularTopics.map((link, index) => (
                    <li key={index} style={{ marginBottom: '8px' }}>
                      <Button
                        onClick={() => navigate(link.url)}
                        color="inherit" sx={{ p: 0, textTransform: 'none' }}>
                        {link.title}
                      </Button>
                    </li>
                  ))}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Partners
              </Typography>
              <Typography variant="body2" component="div">
                <Box component="ul" sx={{ pl: 0, listStyle: 'none' }}>
                  {[ 'Become a Partner', 'For Enterprises', 'For Governments', 'For Universities'].map((link, index) => (
                    <li key={index} style={{ marginBottom: '8px' }}>
                      <Button color="inherit" sx={{ p: 0, textTransform: 'none' }} onClick={() => window.open(`mailto:opencourse.team@gmail.com?subject=Business Partnership Inquiry`, '_blank')}>{link}</Button>
                    </li>
                  ))}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                About
              </Typography>
              <Typography variant="body2" component="div">
                <Box component="ul" sx={{ pl: 0, listStyle: 'none' }}>
                  {[
                    { title: 'About Us', component: 'about-us' },
                    // { title: 'Leadership', component: 'Leadership' },
                    { title: 'Careers', component: 'career' },
                    { title: 'Catalog', component: 'courses' },
                    { title: 'Contact Us', component: 'contact-us' }
                  ].map((link, index) => (
                    <li key={index} style={{ marginBottom: '8px' }}>
                      <Button color="inherit" sx={{ p: 0, textTransform: 'none' }} onClick={() => navigate(`/${link.component}`)}>{link.title}</Button>
                    </li>
                  ))}
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 4, backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2">
                {/* © 2024 Open Course. All rights reserved. */}
              </Typography>
            </Grid>
            <Grid item>
              {/* <Button color="inherit" sx={{ mr: 2 }}>Privacy Policy</Button> */}
              {/* <Button color="inherit" sx={{ mr: 2 }}>Terms of Service</Button> */}
              {/* <Button color="inherit">Accessibility</Button> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
