import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GraduationCap, BookOpen, CheckCircle, Info, User, Video, ChevronDown, ChevronUp } from 'lucide-react';


const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};


export default function CourseDescriptionPage() {
  const { title } = useParams();
  const [activeTab, setActiveTab] = useState('overview');
  const [openLectures, setOpenLectures] = useState({});
  const [courseData, setCourseData] = useState({});

  const toggleLecture = (index) => {
    setOpenLectures((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`https://social.opencoursehub.online/api/content/${encodeURIComponent(title)}`, {
          headers: { accept: 'application/json' },
        });
        const data = await response.json();
        console.log('data', data)
        debugger
        setCourseData(data?.json_data); // Adjust this based on the structure of your response
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [
  ]);

  return (
    <div className="max-w-4xl mx-auto px-6 py-10 space-y-10 bg-white shadow-lg rounded-lg">

      {/* Course Header */}
      <header className="space-y-6 text-center">
        <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">{courseData.rephrased_title}</h1>
        <p className="text-xl text-indigo-600 font-semibold">{courseData.university}</p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
          <div className="inline-flex items-center bg-indigo-100 text-indigo-700 px-6 py-2 rounded-full text-md font-medium shadow-md hover:bg-indigo-200 transition duration-300 ease-in-out">
            <GraduationCap className="mr-3 h-6 w-6" />
            <span>{courseData.category} - {courseData.subcategory}</span>
          </div>
          <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center justify-center">
            <span className="mr-2">Enroll Now</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </header>

      {/* Tab Navigation */}
      <div className="flex space-x-4 border-b border-gray-200">
        {['overview', 'lectures'].map((tab) => (
          <button
            key={tab}
            className={`py-2 px-4 transition-colors duration-300 ${activeTab === tab
              ? 'border-b-2 border-indigo-600 font-semibold text-indigo-600'
              : 'text-gray-500 hover:text-indigo-600'
              }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {activeTab === 'lectures' && (
        <div className="space-y-8">
          {courseData.lectures.map((lecture, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-md transition-transform transform hover:scale-105 hover:shadow-lg">
              <div className="relative">
                <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-6 space-y-4 sm:space-y-0 w-full">
                  <img src={lecture.thumbnail} alt={lecture.title} className="w-full sm:w-24 h-16 object-cover rounded-lg shadow-md" />
                  <div className="flex-grow">
                    <h2 className="text-xl font-medium text-gray-800">{lecture.title}</h2>
                    <p className="text-sm text-gray-500">Duration: {formatDuration(lecture.duration)}</p>
                  </div>
                  {lecture?.chapters?.length > 0 &&
                    (
                      <button
                        onClick={() => toggleLecture(index)}
                        className={`
                                flex items-center justify-center
                                px-4 py-2 text-sm font-medium
                                rounded-full transition-all duration-300 ease-in-out
                                shadow-md hover:shadow-lg
                       ${lecture.chapters && lecture.chapters.length > 0
                            ? openLectures[index]
                              ? "bg-indigo-600 text-white hover:bg-indigo-700 transform hover:scale-105"
                              : "bg-white text-indigo-600 border border-indigo-600 hover:bg-indigo-50"
                            : "bg-gray-100 text-gray-400 cursor-not-allowed opacity-50"
                          }`}
                        disabled={!lecture.chapters || lecture.chapters.length === 0}
                      >
                        {lecture?.chapters?.length > 0 ? (
                          <>
                            {openLectures[index] ? (
                              <>
                                <ChevronUp className="h-4 w-4 mr-2 transition-transform duration-300 ease-in-out" />
                                <span>Chapters</span>
                              </>
                            ) : (
                              <>
                                <ChevronDown className="h-4 w-4 mr-2 transition-transform duration-300 ease-in-out" />
                                <span>Chapters</span>
                              </>
                            )}
                          </>
                        ) : (
                          <span>No Details</span>
                        )}
                      </button>
                    )
                  }
                </div>

                {openLectures[index] && (
                  <ul className="mt-4 space-y-3 pl-8 border-l-2 border-gray-200">
                    {lecture.chapters && lecture.chapters.map((chapter, i) => (
                      <li key={i} className="flex items-center space-x-4 group hover:bg-gray-50 p-2 rounded-lg transition-colors">
                        <Video className="text-indigo-600" />
                        <span className="text-gray-700 font-medium group-hover:text-indigo-600">
                          {chapter.title === '<Untitled Chapter 1>' ? 'Introduction' : chapter.title}
                        </span>
                        <span className="text-gray-500 group-hover:text-gray-700 text-sm">({formatDuration(chapter.end_time - chapter.start_time)})</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {openLectures[index] && (
                <ul className="mt-4 space-y-3 pl-8 border-l-2 border-gray-200">
                  {lecture.chapters && lecture.chapters.length > 0 && lecture.chapters.map((chapter, i) => (
                    <li key={i} className="flex items-center space-x-4 group hover:bg-gray-50 p-2 rounded-lg transition-colors">
                      <Video className="text-indigo-600" />
                      <span className="text-gray-700 font-medium group-hover:text-indigo-600">
                        {chapter.title === '<Untitled Chapter 1>' ? 'Introduction' : chapter.title}
                      </span>
                      <span className="text-gray-500 group-hover:text-gray-700 text-sm">({formatDuration(chapter.end_time - chapter.start_time)})</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}


      {/* Overview Section */}
      {activeTab === 'overview' && (
        <>
          <section className="space-y-2">
            <h2 className="text-2xl font-semibold text-gray-800">Course Overview</h2>
            <p className="text-gray-700 leading-relaxed">{courseData.rephrased_description}</p>
          </section>

          {/* Instructor Information */}
          <section className="flex items-center space-x-4 py-4 border-t border-gray-200">
            <User className="h-8 w-8 text-indigo-600" />
            <div>
              <h3 className="text-lg font-medium text-gray-800">Instructor</h3>
              <p className="text-gray-600">{courseData.professor}</p>
            </div>
          </section>

          {/* What You'll Learn */}
          {courseData?.what_You_Learn?.length > 0 &&
            (
              <section className="space-y-4">
                <h3 className="text-2xl font-semibold text-gray-800">What You'll Learn</h3>
                <ul className="space-y-3">
                  {courseData?.what_You_Learn?.map((item, index) => (
                    <li key={index} className="flex items-start space-x-3">
                      <CheckCircle className="h-6 w-6 text-green-600" />
                      <span className="text-gray-700 leading-relaxed">{item}</span>
                    </li>
                  ))}
                </ul>
              </section>
            )
          }

          {/* Prerequisites */}
          {courseData?.prerequisites_for_course?.length > 0 &&
            (<section className="space-y-4">
              <h3 className="text-2xl font-semibold text-gray-800">Prerequisites</h3>
              <ul className="space-y-3">
                {courseData?.prerequisites_for_course?.map((item, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <BookOpen className="h-6 w-6 text-orange-500" />
                    <span className="text-gray-700 leading-relaxed">{item}</span>
                  </li>
                ))}
              </ul>
            </section>)
          }

          {/* Skills You'll Gain */}
          {courseData?.skills_You_Gain?.length > 0 && (
            <section className="space-y-4">
              <h3 className="text-2xl font-semibold text-gray-800">Skills You'll Gain</h3>
              <ul className="space-y-3">
                {courseData.skills_You_Gain.map((skill, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <CheckCircle className="h-6 w-6 text-teal-500" />
                    <span className="text-gray-700 leading-relaxed">{skill}</span>
                  </li>
                ))}
              </ul>
            </section>
          )}
        </>
      )}
    </div>
  );
}
