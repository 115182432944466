import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Typography, Grid, Card, CardContent, CardMedia, Chip, Tabs, Tab, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import courseData from './course_list.json';
import CallToAction from './CallToAction';

const HomePage = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [featuredCourses, setFeaturedCourses] = useState([]);

  useEffect(() => {
    setFeaturedCourses(course1)
    console.log('HomePage mounted');
  }, []);

  const handleSignIn = () => {
    navigate('/signin');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const topPartners = [
    { name: 'MIT', logo: 'MIT-Logo.wine.svg', url: "/courses?search?q=MIT" },
    { name: 'Harvard', logo: 'Harvard.svg.png', url: "/courses?search?q=Harvard" },
    { name: 'Stanford', logo: 'Stanford.jpg', url: "/courses?search?q=Stanford" },
    // { name: 'Google', logo: '/api/placeholder/100/50' },
    // { name: 'IBM', logo: '/api/placeholder/100/50' },
  ];

  const popularTopics = [
    {
      title: "Computer Science",
      url: "/courses?categories=Computer+Science"
    },
    {
      title: "Economics",
      url: "/courses?categories=Economics"
    },
    {
      title: "Biology",
      url: "/courses?categories=Biology"
    },
    {
      title: "Mathematics",
      url: "/courses?categories=Mathematics"
    },
    {
      title: "Physics",
      url: "/courses?categories=Physics"
    }
  ];

  const course1 = [
    {
      title: "Introduction to Programming with Python",
      title_url: courseData.find(c => c.title.includes("Introduction to Programming with Python"))?.old_title,
      institution: "Harvard",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0ELzNTOsZcUcopDIxuyWDLo-mHuDnp5e94A&s",
    },
    {
      title: "Introduction to Databases with SQL",
      title_url: courseData.find(c => c.title.includes("Introduction to Databases with SQL"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi_webp/wdzA1Z8tKek/maxresdefault.webp",
    },
    {
      title: "Introduction to Deep Learning",
      title_url: courseData.find(c => c.title.includes("Introduction to Deep Learning"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi_webp/ErnWZxJovaM/maxresdefault.webp",
    },
    {
      title: "Introduction to Probability",
      title_url: courseData.find(c => c.title.includes("Introduction to Probability"))?.old_title,
      institution: "MIT",
      image: "https://i.ytimg.com/vi_webp/1uW3qMFA9Ho/sddefault.webp",
    },
    {
      title: "Swift iOS Application Development",
      title_url: courseData.find(c => c.title.includes("Swift iOS Application Development"))?.old_title,
      institution: "MIT",
      image: "https://i.ytimg.com/vi/n1qabtjZ_jg/maxresdefault.jpg",
    },
    {
      title: "Introduction to Artificial Intelligence with Python",
      title_url: courseData.find(c => c.title.includes("Introduction to Artificial Intelligence with Python"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi/gR8QvFmNuLE/maxresdefault.jpg",
    },
  ];

  const course2 = [
    {
      title: "Computer Science for Business Leaders",
      title_url: courseData.find(c => c.title.includes("Computer Science for Business Leaders"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi_webp/ET5lWa3MkBo/maxresdefault.webp",
    },
    {
      title: "Computer Science for Lawyers",
      title_url: courseData.find(c => c.title.includes("Computer Science for Lawyers"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi_webp/nrB1aMKgjvU/maxresdefault.webp",
    },
    {
      title: "Introduction To Psychology",
      title_url: courseData.find(c => c.title.includes("Introduction To Psychology"))?.old_title,
      institution: "MIT",
      image: "https://i.ytimg.com/vi/jQdf2XgbLZo/maxresdefault.jpg",
    },
    {
      title: "Exploring Animal Behavior: Concepts and Approaches",
      title_url: courseData.find(c => c.title.includes("Exploring Animal Behavior: Concepts and Approaches"))?.old_title,
      institution: "Harvard",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHExAQ6nB14kBevaknU2adIuZkQdZnanYnIg&s",
    },
    {
      title: "Development Economics: Theory and Applications",
      title_url: courseData.find(c => c.title.includes("Development Economics: Theory and Applications"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi/fIFB6SBw2lU/maxresdefault.jpg",
    },
    {
      title: "Einstein, Oppenheimer, Feynman: Physics In The 20th Century",
      title_url: courseData.find(c => c.title.includes("Einstein, Oppenheimer, Feynman: Physics In The 20th Century"))?.old_title,
      institution: "MIT",
      image: "https://i.ytimg.com/vi_webp/PbITFIGLciI/maxresdefault.webp",
    },
  ];

  const course3 = [
    {
      title: "Genomics and Computational Biology",
      title_url: courseData.find(c => c.title.includes("Genomics and Computational Biology"))?.old_title,
      institution: "MIT",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-hoGCBHUd4Yek-oxwXLFwR5i0QtStyi_y8g&s",
    },
    {
      title: "Single Variable Calculus",
      title_url: courseData.find(c => c.title.includes("Single Variable Calculus"))?.old_title,
      institution: "MIT",
      image: "https://i.ytimg.com/vi/0YqjeqLhDDE/maxresdefault.jpg",
    },
    {
      title: "Sustainable Real Estate",
      title_url: courseData.find(c => c.title.includes("Sustainable Real Estate"))?.old_title,
      institution: "MIT",
      image: "https://i.ytimg.com/vi_webp/wHaBjy75VZw/maxresdefault.webp",
    },
    {
      title: "Introduction to Deep Learning",
      title_url: courseData.find(c => c.title.includes("Introduction to Deep Learning"))?.old_title,
      institution: "Harvard",
      image: "https://i.ytimg.com/vi_webp/ErnWZxJovaM/maxresdefault.webp",
    },
    {
      title: "Brain Structure and Its Origins",
      title_url: courseData.find(c => c.title.includes("Brain Structure and Its Origins"))?.old_title,
      institution: "MIT",
      image: "https://cdn.britannica.com/72/74272-050-A44DE01A/view-hemisphere-lobes-human-brain-skull-convolutions.jpg",
    },
    {
      title: "Blockchain Fundamentals Decal",
      title_url: courseData.find(c => c.title.includes("Blockchain Fundamentals Decal"))?.old_title,
      institution: "Berkeley",
      image: "https://i.ytimg.com/vi/_tyKNL4lDKk/maxresdefault.jpg",
    },
  ];

  const careerData = [
    { name: '', value: 1 },
    { name: '', value: 2 },
    { name: '', value: 4 },
    { name: '', value: 8 },
    { name: '', value: 16 },
    { name: '', value: 32 },
  ];

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Home', 'Courses', 'My Learning', 'For Business'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index === 0 ? <HomeIcon /> :
                index === 1 ? <LibraryBooksIcon /> :
                  index === 2 ? <AccountCircleIcon /> : <BusinessIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ backgroundColor: '#f5f5f5' }}>
      {/* Header */}
      {/* <Box sx={{ backgroundColor: '#fff', py: 2, boxShadow: 1, position: 'sticky', top: 0, zIndex: 1000 }}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h4" component="h1" fontWeight="bold" color="primary" display="inline">
                Open Course
              </Typography>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={handleSignIn} sx={{ mr: 2 }}>Sign In</Button>
              <Button variant="contained" color="primary" onClick={handleSignUp}>Register for free</Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContent}
      </Drawer> */}

      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('4924562375_c3dd0d4e3c_b.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: 12,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" component="h2" gutterBottom fontWeight="bold" color="white" textAlign="center">
            Unlock Your Potential
          </Typography>
          <Typography variant="h5" component="p" gutterBottom color="white" textAlign="center">
            Discover world-class courses and transform your career with Open Course
          </Typography>
          <div className="flex justify-center my-8">
            <button onClick={() => navigate('/courses')} className="flex items-center justify-center w-full max-w-xs px-6 py-4 bg-blue-600 text-white text-lg font-semibold rounded-md shadow-lg hover:bg-blue-700 transition-all duration-200 ease-in-out transform hover:scale-105 focus:outline-none">
              <span className="mr-3">Explore Courses</span>
              <SearchIcon className="text-white" />
            </button>
          </div>


        </Container>
      </Box>

      {/* Partners Section */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom textAlign="center">
          Learn from world-class universities and companies
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {topPartners.map((partner, index) => (
            <Grid item key={index}>
              <img onClick={() => navigate(partner.url)} src={partner.logo} alt={partner.name} style={{ width: 200, height: 100, objectFit: 'contain' }} />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={6}>
          {/* Left Column */}
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h3" gutterBottom fontWeight="bold">
              Chart Your Course to Success
            </Typography>
            <Typography variant="body1" paragraph>
              At Open Course, we believe in the power of education to transform lives and careers. Whether you're looking to sharpen your skills, change careers, or advance in your current role, we have the courses and programs to help you achieve your goals.
            </Typography>
            <Box sx={{ height: 300, mb: 4 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={careerData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Grid container spacing={2} justifyContent="center">
              {['Explore Courses', 'Start a Degree', 'Get Certified', 'Learn for Business'].map((text, index) => (
                <Grid item xs={6} sm={3} key={index} sx={{ mb: 2 }}>
                  <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none' }}>{text}</Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* Right Column */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom fontWeight="bold">
                  Why Choose Open Course?
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <StarIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="World-class instructors" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <WorkIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Industry-relevant skills" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <SchoolIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Flexible learning paths" />
                  </ListItem>
                </List>
                <Button variant="contained" color="secondary" fullWidth sx={{ mt: 2 }}>Start Learning Now</Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Featured Courses */}
      <Typography variant="h4" component="h3" gutterBottom fontWeight="bold" textAlign="center" color="primary">
        Featured on Open Course
      </Typography>
      <Box sx={{
        // backgroundImage: 'url(equity-vs-equality.jpeg)', 
        py: 8
      }}
      >
        <Container maxWidth="lg">
          <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 4 }}>
            <Tab label="Career Boost" onClick={() => setFeaturedCourses(course1)} />
            <Tab label="Applied Science & Social Studies" onClick={() => setFeaturedCourses(course2)} />
            <Tab label="Advanced Science & Technology" onClick={() => setFeaturedCourses(course3)} />
          </Tabs>
          <Grid container spacing={4} justifyContent="center">
            {featuredCourses.map((course, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={3}>
                  <CardMedia
                    component="img"
                    height="160"
                    image={course.image}
                    alt={course.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="h4" gutterBottom fontWeight="bold">
                      {course.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {course.institution}
                    </Typography>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <StarIcon sx={{ color: 'gold', mr: 0.5 }} />
                      <Typography variant="body2" fontWeight="bold">{course.
                      
                      
                      
                        ({course.students.toLocaleString()} students)
                      </Typography>
                    </Box> */}
                    {/* <Chip label={course.type} size="small" sx={{ mt: 1 }} /> */}
                  </CardContent>
                  <Button variant="contained" color="primary" sx={{ m: 2 }} onClick={() => navigate(`/course-description/${course.title_url}`)}>Enroll Now</Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Popular Topics */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h4" component="h3" gutterBottom fontWeight="bold" textAlign="center">
          Explore Popular Topics
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {popularTopics.map((topic, index) => (
            <Grid item key={index}>
              <Chip label={topic.title} onClick={() => navigate(topic.url)} clickable color="primary" variant="outlined" />
            </Grid>
          ))}
        </Grid>
      </Container>

      <CallToAction />
    </Box>
  );
};

export default HomePage;
