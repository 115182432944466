import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player/youtube';
import { FaPlay, FaPause, FaForward, FaBackward } from 'react-icons/fa';

const CourseVideoPage = () => {
  const [notes, setNotes] = useState("");
  const [savedNotes, setSavedNotes] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const playerRef = useRef(null);

  const videoUrl = "https://www.youtube.com/watch?v=example_video";
  const chapters = [
    { title: "Introduction", time: 0 },
    { title: "Topic 1 - Basics", time: 60 },
    { title: "Topic 2 - Advanced", time: 300 },
    { title: "Conclusion", time: 600 },
  ];

  const saveNote = () => {
    setSavedNotes([...savedNotes, { time: currentTime, text: notes }]);
    setNotes("");
  };

  const addBookmark = () => {
    if (!bookmarks.includes(currentTime)) {
      setBookmarks([...bookmarks, currentTime]);
    }
  };

  const seekTo = (time) => {
    if (playerRef.current) {
      playerRef.current.seekTo(time, "seconds");
      setCurrentTime(time);
    }
  };

  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };

  return (
    <div className="flex flex-col items-center gap-8 p-4 max-w-4xl mx-auto">
      {/* Video Player */}
      <div className="relative w-full h-[300px] md:h-[500px]">
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          playing={isPlaying}
          controls={false} // Hide native controls for custom controls
          onProgress={handleProgress}
          width="100%"
          height="100%"
          className="rounded-lg shadow-lg"
        />
        {/* Custom Controls */}
        <div className="absolute bottom-4 left-4 right-4 flex items-center justify-center gap-4 bg-black/70 p-3 rounded-lg text-white text-xl">
          <FaBackward
            onClick={() => seekTo(currentTime - 10)}
            className="cursor-pointer"
          />
          {isPlaying ? (
            <FaPause
              onClick={() => setIsPlaying(false)}
              className="cursor-pointer"
            />
          ) : (
            <FaPlay
              onClick={() => setIsPlaying(true)}
              className="cursor-pointer"
            />
          )}
          <FaForward
            onClick={() => seekTo(currentTime + 10)}
            className="cursor-pointer"
          />
        </div>
      </div>

      {/* Chapters Section */}
      <div className="w-full">
        <h3 className="text-2xl font-semibold mb-2">Chapters</h3>
        <ul className="space-y-2">
          {chapters.map((chapter, index) => (
            <li
              key={index}
              onClick={() => seekTo(chapter.time)}
              className={`cursor-pointer text-lg p-2 rounded ${
                currentTime >= chapter.time &&
                (index === chapters.length - 1 || currentTime < chapters[index + 1].time)
                  ? "bg-blue-500 text-white"
                  : "text-blue-500"
              }`}
            >
              {chapter.title}
            </li>
          ))}
        </ul>
      </div>

      {/* Notes Section */}
      <div className="w-full">
        <h3 className="text-2xl font-semibold mb-2">Notes</h3>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Type your notes here..."
          className="w-full p-3 border border-gray-300 rounded-md resize-none"
          rows="4"
        />
        <button
          onClick={saveNote}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Note at {currentTime.toFixed(2)}s
        </button>
        <div className="mt-4 space-y-2">
          {savedNotes.map((note, index) => (
            <p key={index}>
              <span
                onClick={() => seekTo(note.time)}
                className="text-blue-500 cursor-pointer hover:underline"
              >
                At {note.time.toFixed(2)}s:
              </span>{" "}
              {note.text}
            </p>
          ))}
        </div>
      </div>

      {/* Bookmarks Section */}
      <div className="w-full">
        <h3 className="text-2xl font-semibold mb-2">Bookmarks</h3>
        <button
          onClick={addBookmark}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add Bookmark
        </button>
        <ul className="mt-4 space-y-2">
          {bookmarks.map((time, index) => (
            <li
              key={index}
              onClick={() => seekTo(time)}
              className="text-blue-500 cursor-pointer hover:underline"
            >
              Bookmark at {time.toFixed(2)}s
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CourseVideoPage;
